import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You've probably seen some photos of parks in the last few weeks, showing people out in groups, apparently close together. Lenses can easily erase depth, making it seem like everyone is on top of each other. I filmed my ride yesterday, and you can get a sense of how apparently dense crowds in the distance tend to dissolve into reasonably spaced groups as I get closer.`}</p>
    <p>{`The second striking experience of riding down JFK and the Great Highway is how claustrophobic and dangerous the areas with cars feel in comparison to the slow streets. You can really get a sense of how much more secure the open areas are, especially for families and young children. Hope you enjoy!`}</p>
    <div className="iframe-container">
      <iframe src="https://player.vimeo.com/video/419652421" width="640" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen style={{
        maxWidth: "100%"
      }}></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      